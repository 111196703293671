import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import Button from '../Button/Button';
import Quote from '../Quote/Quote';
import OrderedList from '../OrderedList/OrderedList';
import Icon from '../Icon/Icon';
import BestRanking from '../BestRanking/BestRanking';
import PremiumRanking from '../PremiumRanking/PremiumRanking';
import Box from '../Box/Box';
import QuotesWidget from '../QuotesWidget/QuotesWidget';
import StaticTable from '../StaticTable/StaticTable';
import Columns from '../Columns/Columns';
import ExpandCollapse from '../ExpandCollapse/ExpandCollapse';
import Stars from "../StarRanking/Stars";
import ColumnRangeChart from '../ColumnRangeChart/ColumnRangeChart';
import BreakLine from '../BreakLine/BreakLine';
import StateCrossLinking from '../StateCrossLinking/StateCrossLinking';
import QuoteButton from '../QuoteButton/QuoteButton';
import ProsCons from '../ProsCons/ProsCons';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import CompanyCrossLinking from '../CompanyCrossLinking/CompanyCrossLinking';
import ListRanking from '../ListRanking/ListRanking';
import Spacing from '../Spacing/Spacing';
import StaticPremiumTable from '../StaticPremiumTable/StaticPremiumTable';
import Badge from '../Badge/Badge';
import { Subnav } from './DummyComponents';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "content-validator"
    }}>{`Content Validator`}</h1>
    <p>{`You can use this content validator to check HTML pages written on Strapi`}</p>
    <Playground __position={0} __code={''} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Quote,
      OrderedList,
      Icon,
      BestRanking,
      PremiumRanking,
      Box,
      QuotesWidget,
      StaticTable,
      Columns,
      ExpandCollapse,
      Stars,
      ColumnRangeChart,
      BreakLine,
      StateCrossLinking,
      QuoteButton,
      ProsCons,
      CompanyLogo,
      CompanyCrossLinking,
      ListRanking,
      Spacing,
      StaticPremiumTable,
      Badge,
      Subnav,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      